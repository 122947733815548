<template>
  <div class="online-pay-title">{{ type }}扫码支付</div>
</template>

<script>
export default {
  props: {
    type: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";

.online-pay-title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: $primary-text-color;
  margin-top: 56px;
}
</style>
