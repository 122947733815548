export const PayType = Object.freeze({
  "ali-pay": {
    name: "支付宝",
    type: "ali-pay",
  },
  "wx-pay": {
    name: "微信",
    type: "wx-pay",
  },
  bank: {
    name: "银行转账",
    accountName: {
      label: "开户名称：",
      value: "中新宽维传媒科技有限公司",
    },
    open: {
      label: "开户行：",
      value: "招商银行股份有限公司",
    },
  },
});

export default {};
