<template>
  <div class="on-line-pay-warn">
    <i class="iconfont">图标</i>
    <span>请您完成付款后，点击下方按钮确认支付完成。</span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";

.on-line-pay-warn {
  font-size: 18px;
  color: $primary-text-color;
  text-align: center;

  .iconfont {
    color: #f67777;
  }
}
</style>
