<template>
  <div class="qr-code-wrap">
    <img ref="qrCode" class="qr-code" src="" alt="" />
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  props: {
    type: String, // 根据支付类型，生成不同的支付二维码
  },
  computed: {
    qrCodeVal() {
      return "https://www.baidu.com";
    },
  },
  mounted() {
    this.createQRCode();
  },
  methods: {
    createQRCode() {
      QRCode.toDataURL(
        this.qrCodeVal,
        {
          errorCorrectionLevel: "H",
          width: 200,
          margin: 0,
        },
        (err, url) => {
          this.$refs.qrCode.src = url;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";

.qr-code-wrap {
  background: $white;
  margin: 0 auto;
  padding: 20px;
  width: 260px;
  height: 260px;
  background: $white;
  box-shadow: 0px 0px 16px 4px rgba(67, 92, 130, 0.1);
  border-radius: 10px;

  .qr-code {
    width: 100%;
    height: 100%;
  }
}
</style>
