<template>
  <div class="pay-tip">
    <div>打开手机{{ type }}，扫一扫继续付款</div>
    <div>交易将在<span class="end-time">30</span>分钟后关闭，请及时付款！</div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";

.pay-tip {
  font-size: 14px;
  color: $primary-text-color;
  text-align: center;

  & > div:first-child {
    margin-bottom: 10px;
  }

  .end-time {
    color: #f67878;
  }
}
</style>
