<template>
  <div class="content-container">
    <card-title title="在线支付" />
    <on-line-pay-title :type="payType.name" />
    <qr-code />
    <on-line-pay-tip :type="payType.name" />
    <div class="divide"></div>
    <on-line-pay-warn />
    <on-line-pay-btn />
  </div>
</template>

<script>
import OnLinePayTitle from "@/views/pay/OnLinePayTitle";
import CardTitle from "@/components/CardTitle";
import QrCode from "@/views/pay/QrCode";
import OnLinePayTip from "@/views/pay/OnLinePayTip";
import OnLinePayWarn from "@/views/pay/OnLinePayWarn";
import OnLinePayBtn from "@/views/pay/OnLinePayBtn";
import { PayType } from "@/views/pay/payConfig";

export default {
  components: {
    OnLinePayBtn,
    OnLinePayTitle,
    CardTitle,
    QrCode,
    OnLinePayTip,
    OnLinePayWarn,
  },
  data() {
    return {
      // payType: {},
    };
  },
  computed: {
    payType() {
      const { type } = this.$route.params;
      const payType = PayType[type];
      return payType || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.online-pay-title {
  margin-bottom: 20px;
}
.qr-code-wrap {
  margin-bottom: 24px;
}

.pay-tip {
  margin-top: 24px;
  margin-bottom: 16px;
}

.divide {
  width: 579px;
  height: 1px;
  border: 1px solid #c4d6fc;
  margin: 24px auto 0;
}

.on-line-pay-warn {
  margin-top: 24px;
  margin-bottom: 16px;
}
</style>
