<template>
  <a-button class="on-line-pay-btn" type="primary">已完成支付</a-button>
</template>

<script>
import { Button } from "ant-design-vue";

export default {
  components: {
    [Button.name]: Button,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

.on-line-pay-btn {
  width: 320px;
  height: 44px;
  //background: $primary-color;
  border-radius: 22px;
  @include flex(row, center, center);
  margin: 0 auto;
  font-size: $font-size-description;
  color: $white;
}
</style>
